

import i from './interCard.module.scss' 
import h from '../../components/Header/Header.module.scss' 
import s from '../../Home.module.scss' 
import CardKarzinaAdd from '../../components/Content/img/cardKarzinaAdd.svg';
import CardKarzina from '../../components/Content/img/cardKarzina.svg';

import { useParams } from 'react-router-dom'
import Card from '../../components/Content/Card'
import ContentLogo from '../../components/Content/ContentLogo'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ITEMS_PER_PAGE = 8;
export default function InterCard ({
    
    addBasket,
    Goods,
    isAddedToCart,
    karzinkaTovar,
    setIsAddedToCart,
    isActive

}) {

    const params = useParams()
    const userId = Goods.findIndex(user => user.id === +params.userId)
    const mas = Goods[userId]
    const [currentPage, setCurrentPage] = useState(1);
            const [currentGoods, setCurrentGoods] = useState([]);
        
            useEffect(() => {
                // Загружаем первые 8 товаров при первом рендере
                setCurrentGoods(Goods.slice(0, ITEMS_PER_PAGE));
            }, [Goods]);
        
            const loadMoreGoods = () => {
                const nextPage = currentPage + 1;
                const indexOfLastProduct = nextPage * ITEMS_PER_PAGE;
                const indexOfFirstProduct = indexOfLastProduct - ITEMS_PER_PAGE;
        
                // Получаем новые товары для следующей страницы
                const newGoods = Goods.slice(indexOfFirstProduct, indexOfLastProduct);
                setCurrentGoods((prevGoods) => [...prevGoods, ...newGoods]);
                setCurrentPage(nextPage);
            };

    return (


        <>
        
        {mas &&

        <section className={i.section__inter}>

            <div className={h.container}>
                
                <div className={i.inter}>
                    
                    <div className={i.inter__flex}>

{mas && mas.images && mas.images[0] && mas.images[0].images ? (

    <img src={mas.images[0].images} alt="img" className={i.inter__flex__img} />

  ) : (

    <p>No image available</p>

)}
                    </div>

                    <div className={i.inter__item}>

                        <p className={i.inter__item__title}>
                        {mas.title}
                        </p>

                        <p className={i.inter__item__subtitle}>
                       {mas.description}
                        </p>

                        <p className={i.inter__item__subtitle}>
                            <span>Состав:</span> {mas.compound}
                        </p>

                        <div className={i.inter__item__info}>
                            
                            <p className={i.inter__item__info__title}>
                            {mas.weight} г.
                            </p>

                        </div>

                        <div className={i.inter__item__info}>
                            
                            <p className={i.inter__item__info__sum}>
                            {mas.price} руб.
                            </p>


        {isAddedToCart ? (

          <div>
            <img src={CardKarzinaAdd} alt="svg" className={s.mycard__item__footer__add} />
          </div>

        ) : (

          <Link to={'/basket'}>
          
          <img
            src={CardKarzina}
            id={mas.id}
            onClick={() => {
              addBasket(mas.id);
              setIsAddedToCart(true); // Установите состояние isAddedToCart в true, когда товар добавляется в корзину
            }}
            className={s.mycard__item__footer__kar}
            alt="svg"
          />
          
          </Link>
        )}                            
      </div>
      {isAddedToCart ? <p className={s.mycard__item__footer__text}>Товар добавлен в карзину</p> : ''}

                    </div>

                    </div>

                </div>

        </section>

        }
 
        <section className={i.section__mycard}>

        <ContentLogo Title='Что-нибудь еще?' />
        
         <div className={h.container}>
          <div className={s.mycard}>
            {currentGoods.map((info, index) => {
                                        return <Card addBasket={addBasket} isAddedToCart={karzinkaTovar.some((item) => item.id === info.id)} {...info} key={index} isActive={isActive} />
                                    })}
                
          </div>

          <div className={s.pagination__container}>
                                  {currentGoods.length < Goods.length && (
                                      <button className={s.pagination__container_btn} onClick={loadMoreGoods}>
                                          Загрузить еще товары
                                      </button>
                                  )}
                              </div>
         </div>
        </section>
        
        </>

        
        

    )
}