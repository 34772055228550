import b from './Basket.module.scss';
import h from '../../components/Header/Header.module.scss';
import i from '../InterCard/interCard.module.scss';
import s from '../../Home.module.scss';
import { useEffect, useState, useCallback, useMemo } from 'react';
import * as turf from '@turf/turf';
import BasketTovar from './BasketTovar';
import ContentLogo from '../../components/Content/ContentLogo';
import Card from '../../components/Content/Card';
import axios from 'axios';
import debounce from 'lodash.debounce';

const API_KEY = 'bd5f3daa-9b32-4e5a-98ad-25e37bf9c8b2';

const deliveryZone = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[
          [37.75614661733558,55.75961475739184],
          [37.75548964670668,55.746332706428625],
          [37.80733138254653,55.74081316118572],
          [37.83788710764417,55.74120052327509],
          [37.837372123513305,55.726090540293065],
          [37.86381109002656,55.728388676172415],
          [37.86497993032383,55.73298724336863],
          [37.885579295558195,55.740832152679836],
          [37.89347571889804,55.750902307186685],
          [37.88729590932776,55.77161537648299],
          [37.8677265123551,55.78032318014273],
          [37.92694968740395,55.79164040409415],
          [37.923001475734026,55.811751762348706],
          [37.82326621572425,55.795702192040466],
          [37.8234378771012,55.78390248826773],
          [37.7930538133805,55.77867852246463],
          [37.75614661733558,55.75961475739184]
        ]]
      }
    }
  ]
};

const ITEMS_PER_PAGE = 8;

async function geocodeAddress(address, apiKey) {
  try {
    const response = await axios.get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&geocode=${encodeURIComponent(address)}`
    );
    
    const featureMember = response.data.response.GeoObjectCollection.featureMember;
    if (featureMember.length === 0) {
      throw new Error('Адрес не найден');
    }
    
    const pos = featureMember[0].GeoObject.Point.pos.split(' ');
    return {
      longitude: parseFloat(pos[0]),
      latitude: parseFloat(pos[1])
    };
  } catch (error) {
    console.error('Ошибка геокодирования:', error);
    throw error;
  }
}

function isPointInDeliveryZone(point, deliveryZone) {
  const pt = turf.point([point.longitude, point.latitude]);
  const polygon = turf.polygon(deliveryZone.features[0].geometry.coordinates);
  return turf.booleanPointInPolygon(pt, polygon);
}

export default function Basket({
  isAddedToCart,
  setIsAddedToCart,
  karzinkaTovar,
  setkarzinkaTovar,
  addBasket,
  Goods,
  totalCartPrice, 
  setTotalCartPrice,
  isActive, 
  address, 
  setAddress
}) {
  const [instrumentation, setInstrumentation] = useState(1);
  const [deliveryAmount, setDeliveryAmount] = useState(150);
  const [countInfo, setCountInfo] = useState([]);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [delTime, setDelTime] = useState('');
  const [delTimeSum, setDelTimeSum] = useState('');
  const [oplata, setOplata] = useState('Оплата онлайн');
  const [addressError, setAddressError] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [goodDisc, setGoodsDisc] = useState('');
  const [goodId, setGoodId] = useState([]);
  const [countGood, setCountGood] = useState([]);
  const [priceGood, setPriceGood] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentGoods, setCurrentGoods] = useState([]);

  const checkAddressInZone = useCallback(async (address) => {
    if (!address || address.length < 5) {
      setAddressError('Пожалуйста, укажите более точный адрес');
      return false;
    }


    const requiredCities = ['Москва', 'Реутов', 'Балашиха', 'Люберцы']; // Добавьте нужные города
    const hasCity = requiredCities.some(city => address.toLowerCase().includes(city.toLowerCase()));
    
    if (!hasCity) {
      setAddressError('Пожалуйста, укажите город в адресе (Москва, Реутов и др.)');
      return false;
    }
  
    try {
      const coords = await geocodeAddress(address, API_KEY);
      const isInZone = isPointInDeliveryZone(coords, deliveryZone);
      
      if (!isInZone) {
        setAddressError('Ваш адрес вне зоны доставки');
        return false;
       } else {
         setAddressError('');
         return true;
        }
      } catch (error) {
        setAddressError('Не удалось проверить адрес. Пожалуйста, укажите более точный адрес.');
      }
  }, []);

  const debouncedCheckAddress = useMemo(
    () => debounce(checkAddressInZone, 500),
    [checkAddressInZone]
  );

  const handleAddressChange = async (event) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    
    if (newAddress.length > 5) {
      await debouncedCheckAddress(newAddress);
    } else {
      setAddressError('');
    }
  };

  const calculatePrices = useCallback(() => {
    const goodsPrice = karzinkaTovar.reduce((total, item) => total + item.price * item.count, 0);
    const delivery = goodsPrice >= 1000 ? 0 : 150;
    setDeliveryAmount(delivery);
    const totalPrice = goodsPrice + delivery;
    setFinalPrice(totalPrice);
    setTotalCartPrice(totalPrice);
  }, [karzinkaTovar, setTotalCartPrice]);

  useEffect(() => {
    calculatePrices();
  }, [calculatePrices]);

  useEffect(() => {
    setGoodsDisc(karzinkaTovar.map(el => el.title));
    setGoodId(karzinkaTovar.map(el => el.id));
    setCountGood(karzinkaTovar.map(el => el.count));
    let priceGoods =  karzinkaTovar.map(el => el.price * el.count);
    setPriceGood(priceGoods);
    let totalPrice = priceGoods.reduce((prev, curr) => prev + curr, 0)
    setFinalPrice(totalPrice)
  }, [karzinkaTovar]);

  console.log(karzinkaTovar, 'korzinka')
  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setDelTimeSum(selectedTime);
  
    const [hours, minutes] = selectedTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
  
    if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
      setError("Заведение не работает в это время.");
    } else {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      if (!delTimeSum) {
        setError("Пожалуйста, укажите время доставки");
        return;
      }
  
      // Используем логику проверки времени из handleTimeChange
      const [hours, minutes] = delTimeSum.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;
      if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
        setError("Заведение не работает в это время.");
        return;
      }

      const isAddressValid = await checkAddressInZone(address);
      if (!isAddressValid) {
        return;
      }

      const deliveryTime = `${delTime} ${delTimeSum}`;

      
      const response = await axios.post(
        'https://tyteda.ru/api/send-order/',
        {
          decription: goodDisc.join(', '),
          goods_id: goodId,
          count_goods: countGood, // Теперь здесь точно актуальные данные
          price_goods: priceGood,
          address: address,
          delivery_time: deliveryTime,
          final_price: finalPrice,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );


      const paymentResponse = await axios.post(
        'https://tyteda.ru/api/payment/',
        {
          service_name: goodDisc.join(', '),
          num_order: goodId,
          price: finalPrice
        },
        {
          headers: {
            authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      await axios.patch(
        'https://tyteda.ru/api/users/me/',
        { delivery_address: address },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );

      if (paymentResponse.data.success) {
        window.location.href = paymentResponse.data.success;
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setError(err.response.data.error || 'Ошибка при оформлении заказа');
      } else {
        setError('Произошла ошибка при обработке заказа. Пожалуйста, попробуйте позже.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDeliveryAddress = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get('https://tyteda.ru/api/users/me/', {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        }
      });
      
      setAddress(response.data.delivery_address || '');
      setName(response.data.first_name || '');
      setMail(response.data.email || '');

      if (response.data.delivery_address) {
        await checkAddressInZone(response.data.delivery_address);
      }
    } catch (error) {
      console.error('Ошибка запроса', error);
    }
  }, [checkAddressInZone, setAddress]);

  useEffect(() => {
    fetchDeliveryAddress();
  }, [fetchDeliveryAddress]);

  useEffect(() => {
    setCurrentGoods(Goods.slice(0, ITEMS_PER_PAGE));
  }, [Goods]);

  const loadMoreGoods = () => {
    const nextPage = currentPage + 1;
    const newGoods = Goods.slice(
      currentPage * ITEMS_PER_PAGE,
      nextPage * ITEMS_PER_PAGE
    );
    setCurrentGoods(prev => [...prev, ...newGoods]);
    setCurrentPage(nextPage);
  };

  const updateCartItem = useCallback((id, newCount) => {
    setkarzinkaTovar(prev => 
      prev.map(item => 
        item.id === id ? { ...item, count: newCount } : item
      )
    );
    calculatePrices();
  }, [calculatePrices]);

  const fetchCartItems = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get('https://tyteda.ru/api/goods/shopping_cart/', {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        }
      });
      
      setkarzinkaTovar(response.data.map(item => ({
        ...item.goods,
        count: item.count
      })));
    } catch (error) {
      console.error('Ошибка загрузки корзины', error);
    }
  }, [setkarzinkaTovar]);

  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  const removeBasket = async (id) => {
    try {
      await axios.delete(`https://tyteda.ru/api/goods/${id}/shopping_cart/`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      setkarzinkaTovar(prev => prev.filter(item => item.id !== id));
      calculatePrices();
    } catch (err) {
      setError('Не удалось удалить товар из корзины');
    }
  };


  return (
    <>
      <section className={b.section__basket}>
        <div className={h.container}>
          <form className={b.basket} onSubmit={handleSubmit}>
            <div className={b.basket__item}>
              <p className={b.basket__item__title}>Личные данные</p>
              <div className={b.basket__item__form}>
                <label className={b.basket__item__form__label}>
                  <p className={b.basket__item__form__label__text}>Имя</p>
                  <input 
                    type="text" 
                    placeholder="ФИО" 
                    className={b.basket__item__form__label__inp}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>

                <label className={b.basket__item__form__label}>
                  <p className={b.basket__item__form__label__text}>Почта</p>
                  <input 
                    type="email" 
                    placeholder="example@mail.ru" 
                    className={b.basket__item__form__label__inp}
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    required
                  />
                </label>

                <label className={b.basket__item__form__label}>
                  <p className={b.basket__item__form__label__text}>Адрес</p>
                  <input 
                    type="text" 
                    placeholder="Реутовских Ополченцев д 14, кв. 551" 
                    className={`${b.basket__item__form__label__inp} ${addressError ? b.error : ''}`}
                    value={address}
                    onChange={handleAddressChange}
                    required
                  />
                  {addressError && (
                      <p className={b.errorText}>{addressError}</p>
                  )}
                </label>

                <label className={b.basket__item__form__label}>
                  <p className={b.basket__item__form__label__text}>Время доставки</p>
                  <select 
                    className={b.basket__item__form__label__select}
                    value={delTime}
                    onChange={(e) => setDelTime(e.target.value)}
                    required
                  >
                    <option value="">Выберите</option>
                    <option value="Сегодня">Сегодня</option>
                    <option value="Завтра">Завтра</option>
                  </select>
                </label>

                <label className={b.basket__item__form__label}>
                  <input 
                    type="time"
                    className={b.basket__item__form__label__time}
                    value={delTimeSum}
                    onChange={handleTimeChange}
                    required
                  />
                  {error && !addressError && (
                    <p className={b.errorText}>{error}</p>
                  )}
                </label>

                <label className={b.basket__item__form__labelTwo}>
                  <p className={b.basket__item__form__label__text}>Способ оплаты</p>
                  <div className={b.basket__item__form__labelTwo__fl}>
                    <select 
                      className={b.basket__item__form__label__selectTwo}
                      value={oplata}
                      onChange={(e) => setOplata(e.target.value)}
                    >
                      <option value="Оплата онлайн">Оплата онлайн</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>


            <div className={b.basket__item}>
              <p className={b.basket__item__title}>Ваш заказ</p>
              <div className={b.basket__item__formTwo}>
                <div className={b.basket__item__map}>
                  {karzinkaTovar.length === 0 ? (
                    <p className={b.basket__item__map__text}>Пока что нет выбранных товаров.</p>
                  ) : (
                    karzinkaTovar.map((info) => (
                      <BasketTovar
                        key={info.id}
                        info={info}
                        setkarzinkaTovar={setkarzinkaTovar}
                        removeBasket={removeBasket}
                        karzinkaTovar={karzinkaTovar}
                        setTotalCartPrice={setTotalCartPrice}
                        updateCartItem={updateCartItem}
                        calculatePrices={calculatePrices}
                      />
                    ))
                  )}
                </div>

                <div className={b.basket__item__pribor}>
                  <p className={b.basket__item__pribor__title}>Приборы</p>
                  <div className={h.nav__kar__item__fun}>
                    <div 
                      className={h.nav__kar__item__fun__add} 
                      onClick={() => setInstrumentation(prev => Math.max(0, prev - 1))}
                    >
                      -
                    </div>
                    <h3>{instrumentation}</h3>
                    <div 
                      className={h.nav__kar__item__fun__add} 
                      onClick={() => setInstrumentation(prev => prev + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>

                <div className={b.basket__item__priborTwo}>
                  <p className={b.basket__item__pribor__title}>Доставка</p>
                  <p className={b.basket__item__pribor__title}>
                    {deliveryAmount} руб.
                  </p>
                </div>

                <div className={b.basket__item__footer}>
                  <p className={b.basket__item__footer__price}>
                    {totalCartPrice} руб.
                  </p>
                  <button 
                    className={b.basket__item__footer__button} 
                    type="submit"
                    disabled={isLoading || karzinkaTovar.length === 0}
                  >
                    {isLoading ? 'Обработка...' : 'Заказать'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <section className={i.section__mycard}>
        <ContentLogo Title='Что-нибудь еще?' />
        <div className={h.container}>
          <div className={s.mycard}>
            {currentGoods.map((info) => (
              <Card 
                key={info.id}
                addBasket={addBasket} 
                isAddedToCart={karzinkaTovar.some(item => item.id === info.id)} 
                {...info} 
                isActive={isActive} 
              />
            ))}
          </div>
          {currentGoods.length < Goods.length && (
            <div className={s.pagination__container}>
              <button 
                className={s.pagination__container_btn} 
                onClick={loadMoreGoods}
              >
                Загрузить еще товары
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
