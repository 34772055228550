import React, { useEffect } from 'react';

const YandexMetrika = () => {
    useEffect(() => {
        (function (m, e, t, r, i) {
            m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
            m[i].l = 1 * new Date();
            for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) { return; }
            }
            let k = e.createElement(t), a = e.getElementsByTagName(t)[0];
            k.async = 1;
            k.src = r;
            a.parentNode.insertBefore(k, a);
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        if (window.ym) {
            window.ym(100444999, "init", {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            });
        } else {
            console.error("Yandex.Metrika is not loaded");
        }

        // Cleanup function to remove the script when component unmounts
        return () => {
            const script = document.querySelector(`script[src="https://mc.yandex.ru/metrika/tag.js"]`);
            if (script) {
                script.remove();
            }
        };
    }, []);

    return null; // This component does not render anything
};

export default YandexMetrika;