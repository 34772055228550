

export const MenuJson = [

    {

        deyweek: 'Понедельник',
        text1: 'Борщ',
        text2: 'Котлеты куриные',
        text3: 'Макароны',
        text4: 'Оливье',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Вторник',
        text1: 'Суп куриный с вермишелью',
        text2: 'Гуляш из говядины',
        text3: 'Пюре картофельное',
        text4: 'Свекольный с чесноком и сыром',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Среда',
        text1: 'Рассольник с говядиной',
        text2: 'Курочка по-тайски',
        text3: 'Рис',
        text4: 'Салат витаминный',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Четверг',
        text1: 'Гороховый суп',
        text2: 'Рыбные котлеты',
        text3: 'Картофель жареный',
        text4: 'Морковь по-корейски',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Пятница',
        text1: 'Щи из свеж. Капусты',
        text2: 'Курица под сырн. Шапочкой',
        text3: 'Картофельное пюре',
        text4: 'Винегрет',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Суббота',
        text1: 'Харчо',
        text2: 'Голень куриная',
        text3: 'Рагу овощное',
        text4: 'Квашеная капуста',
        text5: 'Морс/Компот/Тан',

    },

    {

        deyweek: 'Воскресение',
        text1: 'Солянка',
        text2: 'Тефтели',
        text3: 'Макароны',
        text4: 'Крабовый салат',
        text5: 'Морс/Компот/Тан',

    },

]