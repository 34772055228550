import d from './Delivery.module.scss'
import h from '../../components/Header/Header.module.scss'
import { useState } from 'react';

export default function Delivery () {
    // const [selectedZone, setSelectedZone] = useState(1);
    // const deliveryCosts = {
    //     1: 100,
    //     2: 200
    // };

    // const handleZoneChange = (event) => {
    //     setSelectedZone(Number(event.target.value));
    // };

    return (
        
        <section className={d.section__delivery}>
            <div className={h.container}>
                

    <div className={d.delivery}>
        
        <div className={d.delivery__info}>

            <p className={d.delivery__info__title}>Бесплатная доставка</p>
            <p className={d.delivery__info__subtitle}>при заказе от 1 000 руб</p>

            <div className={d.delivery__info__center}>

                <div className={d.delivery__info__center__one}>
                    <div className={d.box__one}></div>
                    <p className={d.box__title}>Зона</p>
                    <p className={d.box__text}> - 150 руб</p>
                </div>
{/* 
                <div className={d.delivery__info__center__one}>
                    <div className={d.box__two}></div>
                    <p className={d.box__titleTwo}>Зона 2</p>
                    <p  className={d.box__text}> - 200 руб</p>
                </div> */}

            </div>

            <p className={d.delivery__info__title}>Время доставки </p>
            <p className={d.delivery__info__subtitle}>от 60 мин</p>

        </div>

        <div className={d.delivery__map}>

            {/* <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A75791a82fb35f4aeb7e063ee72bd63894f7b70fdf2d5c9a962d9509054f61982&amp;source=constructor" width="100%" height="626" frameborder="0"></iframe> */}
            <iframe className={d.delivery__map_iframe} src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae9be772e0a333cc7e3337a0125a4bb2b0291916efeffbea32620491728b9539b&amp;source=constructor" width="803" height="524" frameborder="0"></iframe>

        </div>

    </div>

    <div className={d.delivery__footer}>
        
        <p className={d.delivery__footer__title}>
        вы можете забрать свой заказ сами
        </p>

        <div className={d.delivery__footer__info}>

            <p className={d.delivery__footer__info__text}>
            Адрес: г. Москва, Сталеваров ул., д.14, корпус 1
            </p>

            <p className={d.delivery__footer__info__text}>
            Время работы: с 11:00 до 23:00
            </p>

            <p className={d.delivery__footer__info__text}>
            Телефон: +7 (495) 139-64-44
            </p>

        </div>

    </div>
            </div>
        </section>

    )
}